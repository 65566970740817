import React from 'react'
import { useMutation } from '@apollo/client'
import NewsLetterUI, { Props } from '@interflora/ui-components/build/components/NewsLatter/NewsLetter'
import { populateTemplateValues } from 'utils/components'
import { HandleSubmit, NewsletterSignUpValues } from '@interflora/ui-components/build/common/props'
import { NEWSLETTER_SIGNUP } from '../../graphql/queries'
import NewsLetterSignUpProps from '@interflora/ui-components/build/components/NewsletterSignUp/NewsletterSignUp'
import { useLoaded } from '@interflora/ui-components'

type NewsLetterProps = Props & { newsLetterSignUpButton: typeof NewsLetterSignUpProps }
const updateSignUpTemplate: NewsletterSignUpValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const NewsLetter = (props: NewsLetterProps) => {
  const [signUpMutation] = useMutation(NEWSLETTER_SIGNUP)
  const signUp: HandleSubmit<NewsletterSignUpValues> = async (values) => {
    const signUpValues = { ...values, email: values.email.trim() }
    const signUpNewsletterValues = populateTemplateValues(signUpValues, updateSignUpTemplate)
    await signUpMutation({ variables: { input: signUpNewsletterValues } })
  }
  const { newsLetterSignUpButton, ...others } = props
  useLoaded(props)

  return <NewsLetterUI signUp={signUp} newsletterSignUp={newsLetterSignUpButton} {...others} />
}

export default NewsLetter
